import { GlobalContext } from "context/context/GlobalContext"
import { useContext } from "react"
import { PiSpinnerGapBold } from "react-icons/pi"

interface ILabelGenerateDocuments {
  show_loader: boolean
  children: any
  currentStatus: string
}

const LabelGenerateDocuments = ({ currentStatus, show_loader, children }: ILabelGenerateDocuments) => {
  const { state_machine_states } = useContext(GlobalContext)

  const status_index = state_machine_states?.state?.map((st: any) => st?.label).indexOf(currentStatus)

  if (status_index < 6) return <p>No disponible</p>
  if (!show_loader) return children
  return (
    <p className="flex flex-row gap-2">
      Esperando por generación...
      <PiSpinnerGapBold className="order__main__detail__spinner_data font-bold" />
    </p>
  )
}

export default LabelGenerateDocuments
