import { useTranslation } from "react-i18next"
import { formatDate, formatTime } from "helpers/FormatDateHelper"
import LabelGenerateDocuments from "overrides/theme/entel/base/components/Commons/LabelGenerateDocuments"

const OrderInfoPrint = (props: any) => {
  const { t } = useTranslation()
  const { data } = props

  const currentStatus = data?._sg?._omnixStateMachine?.currentState?.state?.name

  const enviameLabel = data?._sg?.enviameLabel ? data?._sg?.enviameLabel[0] : null
  const enviameDocsQty = enviameLabel
    ? Object.values(enviameLabel).filter((doc: any) => doc?.toString()?.includes("http"))?.length
    : 0

  const isGeneratingDTE = !data?._sg?.dte?.folio ? true : false
  const isGeneratingEnviameDocs = enviameDocsQty !== 3 ? true : false

  const template = (
    <section className="order__main__detail">
      <h2>{t("Guía de despacho")}</h2>
      <article className="order__main__detail__summary">
        <h3>Información General</h3>
        <div>
          <p>{t("Fecha de estado actual")}:</p>
          <p>
            <strong>{formatDate(data?._sg?._omnixStateMachine?.currentState?.date)}</strong>
          </p>
        </div>
        {data?._order?.deliveryType && (
          <div>
            <p>{t("Tipo de entrega")}:</p>
            <p>
              <strong>
                {t(
                  `${data?._order?.slotDelivery?.slotType} / ${formatTime(
                    data?._order?.slotDelivery?.initialHour,
                  )} - ${formatTime(data?._order?.slotDelivery?.finalHour)}`,
                )}
              </strong>
            </p>
          </div>
        )}

        <div>
          <p>{t("DTE Folio")}:</p>
          <LabelGenerateDocuments show_loader={Boolean(isGeneratingDTE)} currentStatus={currentStatus}>
            <a href={data?._sg?.dte?.url} target="_blank" rel="noreferrer">
              <strong className="order__main__detail__folio__url">{data?._sg?.dte?.folio}</strong>
            </a>
          </LabelGenerateDocuments>
        </div>
        <div>
          <p>{t("Enviame Documentos")}:</p>
          <div>
            <LabelGenerateDocuments show_loader={Boolean(isGeneratingEnviameDocs)} currentStatus={currentStatus}>
              {enviameLabel &&
                Object.values(enviameLabel).map((_val: any, i: number) => {
                  return (
                    <div key={i}>
                      {_val?.toString()?.includes("http") && (
                        <p>
                          <a href={_val} target="_blank" rel="noreferrer">
                            <strong className="order__main__detail__folio__url">{`Documento ${i}`}</strong>
                          </a>
                        </p>
                      )}
                    </div>
                  )
                })}
            </LabelGenerateDocuments>
          </div>
        </div>
      </article>
    </section>
  )

  return template
}

export default OrderInfoPrint
