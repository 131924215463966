import { post_middleware_state_machines_types } from "services/Middleware"
import useCancelToken from "hooks/UseCancelToken"
import Swal from "sweetalert2"
// import useAuthorizer from "./useAuthorizer"

interface IPostStateMachinesTypes {
  type: "Order" | "ShippingGroup"
}

const usePostMiddlewareStateMachineTypes = ({ type }: IPostStateMachinesTypes) => {
  const { newCancelToken, isCancel } = useCancelToken()
  // const { logout } = useAuthorizer()

  const body_order = {
    type: "entel-order-hd-flow",
    entityType: "Order",
  }
  const body_sg = {
    type: "entel-shipping-group-flow-v3",
    entityType: "ShippingGroup",
  }

  const post_middleware_state_machine_schema = async () => {
    try {
      let body = type === "Order" ? body_order : body_sg
      return await post_middleware_state_machines_types([body], newCancelToken())
    } catch (error: any) {
      if (isCancel(error)) return
      Swal.fire({
        title: "Error",
        text: error?.response?.data?.message?.error,
        icon: "error",
      })
      // logout()
    }
  }

  return {
    post_middleware_state_machine_schema,
  }
}

export default usePostMiddlewareStateMachineTypes
